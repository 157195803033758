import { Box, Container, Divider, Link } from '@mui/material';
import Map from '../../../components/Map/Map';
import Info from '../../../components/Info/Info';
import classes from './Footer.module.css';
import ReseauSociaux from '../../../components/ReseauSociaux/ReseauSociaux';

const Footer = () => {
	return (
		<Box
			component='div'
			className={classes.containerFoot}
			id='contactez-nous'>
			<Box
				component='div'
				className={classes.footerContent}>
				<Box
					component='div'
					className={classes.reseaux}>
					<ReseauSociaux />
				</Box>
				<Box
					component='div'
					className={classes.info}>
					<Info />
				</Box>
				<Box
					component='div'
					className={classes.map}>
					<Map />
				</Box>
			</Box>
			<Divider sx={{backgroundColor:'#655656'}}/>
			<Box
				component='div'
				className={classes.footerBottom}>
				<Container
					maxWidth='md'
					sx={{ display: 'flex', justifyContent: 'center', color: 'white', textAlign: {xs: 'center', sm: 'center'} }}>
					<Link href="https://www.innowebdigital.com/" sx={{textDecoration:'none', fontSize: {xs:'13px'}}} target="_blank">2024 - Create by Innoweb Digital</Link>
				</Container>
				</Box>
		</Box>
	);
};

export default Footer;
