import Hero from './Hero/Hero';
import Presentation from './Presentation/Presentation';
import MenuPizza from './MenuPizza/MenuPizza';
import Footer from './Footer/Footer';

const Home = () => {
	return (
		<>
			<Hero />
			<Presentation />
			<MenuPizza />
			<Footer />
		</>
	);
};

export default Home;
