import { Box, Button, Icon, Typography } from '@mui/material';
import classes from './Hero.module.css';
import PhoneIcon from '@mui/icons-material/Phone';

const Hero = () => (
	<Box className={classes.hero}>
		<Box
			component='div'
			className={classes.heroContent}>
			<Typography
				variant='h2'
				className={classes.heroTitle}>
				BIENVENUE AU{' '}
			</Typography>
			<Typography
				variant='h2'
				className={classes.heroTitle}>
				RESTAURANT L'ARAGONITE
			</Typography>
			<Button
				variant='contained'
				href='tel:+33492602582'
				sx={{ color: '#FFF', backgroundColor: '#462D24', marginTop: '30px' }}>
				<PhoneIcon sx={{ mr: 1 }} />
				RÉSERVEZ VOTRE TABLE
			</Button>
		</Box>
	</Box>
);

export default Hero;
