import {
	Box,
	Divider,
	Typography,
  } from '@mui/material';
  import cartePizzasData from '../../../cartePizza.json';
  import classes from './MenuPizza.module.css';
  
  const MenuPizza = () => {
	// Assurer que 'pizza' est bien défini dans 'cartePizzasData'
	const { left = [], right = [] } = cartePizzasData.pizza || {};
  
	return (
	  <Box
		id='la-carte'
		component='div'
		className={classes.container}>
		<Typography
		  variant='h2'
		  sx={{ my: 10 }}
		  className={classes.titreMenu}>
		  <Divider className={classes.titreMenu}>
			NOS PIZZAS
		  </Divider>
		</Typography>
		<Box
		  component='div'
		  className={classes.menu}>
		  {/* Colonne de gauche */}
		  <Box className={classes.columnMenu}>
			{left.map((pizza, index) => (
			  <Box key={index}>
				<Box className={classes.contentproductcart}>
				  <Typography variant='h6'>{pizza.name}</Typography>
				  <Box className={classes.linePrice} />
				  <Typography variant='h6'>{pizza.prix}</Typography>
				</Box>
				<Typography variant='subtitle1'>
				  Ingrédients: {pizza.ingrédients.join(', ')}
				</Typography>
			  </Box>
			))}
		  </Box>
		  {/* Colonne de droite */}
		  <Box className={classes.columnMenu}>
			{right.map((pizza, index) => (
			  <Box key={index}>
				<Box className={classes.contentproductcart}>
				  <Typography variant='h6'>{pizza.name}</Typography>
				  <Box className={classes.linePrice} />
				  <Typography variant='h6'>{pizza.prix}</Typography>
				</Box>
				<Typography variant='subtitle1'>
				  Ingrédients: {pizza.ingrédients.join(', ')}
				</Typography>
			  </Box>
			))}
		  </Box>
		</Box>
	  </Box>
	);
  };
  
  export default MenuPizza;
  